<template>
  <table
    :class="{ tb: true, selected: this.boxInfo['selected'] }"
    id="tb"
    variant="primary"
    tabindex="0"
    :title="tooltiptext"
  >
    <tr @click="onClick">
      <td :style="boxInfo.color">
        {{ label1 }}
        <em
          v-show="hasError"
          :class="['fa', `fa-times-circle`]"
          :style="errorIconStyle"
        />
        <em
          v-show="hasWarning"
          :class="['fa', `fa-exclamation-triangle`]"
          :style="warningIconStyle"
        />
      </td>
    </tr>
    <tr @click="onClick">
      <td :style="statusStyle">
        {{ label2 }}
      </td>
    </tr>
  </table>
</template>

<script>
import {
  BOX_ITEM_KIND,
  DECISION_FLAG,
  DELETE_STATUS,
  BOX_STATUS_STYLE,
  BOX_EDIT_ERROR_LEVEL,
  NODE_FIX_STATUS,
  IS_DELETED,
} from '@/common/const.js'
import { getNodeFixStatus } from '@/plan/manualPlanning/ManualPlanningEdit.js'
import BoxChartSettings from '@/assets/json/BoxChartSettings.json'
export default {
  name: 'BoxChartItem',
  props: {
    /**
     *
     * @type {Object}
     * @requires
     */
    boxInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartSettings: JSON.parse(
        JSON.stringify(BoxChartSettings.BoxChartSettings)
      ),
    }
  },
  computed: {
    label1() {
      if (this.boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        return this.boxInfo.vehicle.vehicleName || '　' // 車両名
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Trip) {
        return 'トリップ' + (this.boxInfo.gantt.tripNo || 0)
      } else if (this.boxInfo.type === BOX_ITEM_KIND.DepoStart) {
        return '出発'
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Depo) {
        return '荷積'
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Drop) {
        return '荷卸'
      } else if (this.boxInfo.type === BOX_ITEM_KIND.DepoEnd) {
        return '到着'
      } else if (this.boxInfo.type === BOX_ITEM_KIND.LastDepo) {
        return '宵積'
      }
      return '　'
    },
    label2() {
      if (this.boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        return (
          this.getMmddHhmmFromDateTimeString14(
            this.boxInfo.vehicle.scheduleBeginDateTime
          ) || '　'
        ) // 稼動開始日時
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Trip) {
        let totalWeight = 0
        this.boxInfo.items.forEach((item) => {
          item.orderNodes.forEach((orderNode) => {
            if (item.type === BOX_ITEM_KIND.Drop) {
              if (orderNode.order) {
                totalWeight = totalWeight + orderNode.order.weight
              }
            }
          })
        })
        let rate = 0
        if (
          this.boxInfo.maxLoadingWeight &&
          this.boxInfo.maxLoadingWeight > 0
        ) {
          rate = (totalWeight / this.boxInfo.maxLoadingWeight) * 100
        }
        return parseFloat(rate).toFixed(1) + '%' // 積載率
      } else if (
        this.boxInfo.type === BOX_ITEM_KIND.DepoStart ||
        this.boxInfo.type === BOX_ITEM_KIND.Depo ||
        this.boxInfo.type === BOX_ITEM_KIND.Drop ||
        this.boxInfo.type === BOX_ITEM_KIND.DepoEnd ||
        this.boxInfo.type === BOX_ITEM_KIND.LastDepo
      ) {
        return this.boxInfo.gantt.placeName || '　' // 場所名
      }
      return '　'
    },
    statusStyle() {
      if (
        this.boxInfo.type === BOX_ITEM_KIND.Vehicle ||
        this.boxInfo.type === BOX_ITEM_KIND.Trip ||
        this.boxInfo.type === BOX_ITEM_KIND.DepoStart ||
        this.boxInfo.type === BOX_ITEM_KIND.DepoEnd ||
        this.boxInfo.type === BOX_ITEM_KIND.LastDepo
      ) {
        let nodeFixStatus = getNodeFixStatus(this.boxInfo)
        if (nodeFixStatus === NODE_FIX_STATUS.OptimisticLocked) {
          return BOX_STATUS_STYLE.OptimisticLocked
        } else if (nodeFixStatus === NODE_FIX_STATUS.Fixed) {
          return BOX_STATUS_STYLE.Fixed
        } else if (nodeFixStatus === NODE_FIX_STATUS.NeedNotify) {
          return BOX_STATUS_STYLE.NeedNotify
        }
      } else if (
        this.boxInfo.type === BOX_ITEM_KIND.Depo ||
        this.boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        if (
          this.boxInfo.vehicle.ganttUserId ||
          this.boxInfo.vehicle.deleted === IS_DELETED.DELETED
        ) {
          return BOX_STATUS_STYLE.OptimisticLocked
        } else {
          if (
            this.boxInfo.orderNodes.some((orderNode) => {
              if (orderNode.order) {
                if (
                  orderNode.order.delete_status ===
                  DELETE_STATUS.WAIT_FOR_UNDELETE
                ) {
                  return true
                }
              }
            })
          ) {
            return BOX_STATUS_STYLE.WaitForUndelete
          } else if (
            this.boxInfo.orderNodes.some((orderNode) => {
              if (orderNode.order) {
                if (
                  orderNode.order.delete_status ===
                  DELETE_STATUS.WAIT_FOR_DELETE
                ) {
                  return true
                }
              }
            })
          ) {
            return BOX_STATUS_STYLE.WaitForDelete
          } else if (
            this.boxInfo.orderNodes.some((orderNode) => {
              if (orderNode.order) {
                if (orderNode.order.delete_status === DELETE_STATUS.SASIKAE) {
                  return true
                }
              }
            })
          ) {
            if (this.boxInfo.gantt.decisionFlag === DECISION_FLAG.FIXED) {
              return BOX_STATUS_STYLE.Fixed
            } else {
              return BOX_STATUS_STYLE.Sasikae
            }
          } else {
            if (this.boxInfo.gantt.decisionFlag === DECISION_FLAG.FIXED) {
              return BOX_STATUS_STYLE.Fixed
            }
          }
        }
      }
      return BOX_STATUS_STYLE.Init
    },

    tooltiptext() {
      if (this.boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        return this.getTooltipText('Vehicle', this.boxInfo, null)
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Trip) {
        let totalWeight = 0
        let totalVolume = 0
        let totalQuantity = 0
        this.boxInfo.items.forEach((item) => {
          item.orderNodes.forEach((orderNode) => {
            if (item.type === BOX_ITEM_KIND.Depo) {
              if (orderNode.order) {
                totalWeight = totalWeight + orderNode.order.weight
                totalVolume = totalVolume + orderNode.order.volume
                totalQuantity = totalQuantity + orderNode.order.quantity
              }
            }
          })
        })

        let calculatedValues = {}
        calculatedValues['totalWeight'] = totalWeight
        calculatedValues['totalVolume'] = totalVolume
        calculatedValues['totalQuantity'] = totalQuantity

        return this.getTooltipText('Trip', this.boxInfo, calculatedValues)
      } else if (
        this.boxInfo.type === BOX_ITEM_KIND.Depo ||
        this.boxInfo.type === BOX_ITEM_KIND.LastDepo ||
        this.boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        let weight = 0
        let volume = 0
        let quantity = 0
        this.boxInfo.orderNodes.forEach((orderNode) => {
          if (orderNode.order) {
            weight = weight + orderNode.order.weight
            volume = volume + orderNode.order.volume
            quantity = quantity + orderNode.order.quantity
          }
        })

        let beginTime =
          this.getMmddHhmmFromDateTimeString14(this.boxInfo.gantt.beginTime) ||
          ''
        let endTime =
          this.getMmddHhmmFromDateTimeString14(this.boxInfo.gantt.endTime) || ''

        let descriptions = []
        const DESCRIPTION_COUNT = 5
        for (let index = 0; index < DESCRIPTION_COUNT; index++) {
          descriptions.push([])
        }
        this.boxInfo.orderNodes.forEach((orderNode) => {
          if (orderNode.order) {
            for (let index = 0; index < DESCRIPTION_COUNT; index++) {
              let value =
                orderNode.order[
                  'order_description' + (index + 1).toString().padStart(2, '0')
                ]
              if (
                value &&
                descriptions[index].findIndex((e) => e == value) < 0
              ) {
                descriptions[index].push(value)
              }
            }
          }
        })

        let calculatedValues = {}
        calculatedValues['weight'] = weight
        calculatedValues['volume'] = volume
        calculatedValues['quantity'] = quantity
        calculatedValues['beginTime'] = beginTime
        calculatedValues['endTime'] = endTime
        for (let index = 0; index < DESCRIPTION_COUNT; index++) {
          calculatedValues[
            'description' + (index + 1).toString().padStart(2, '0')
          ] = descriptions[index].join(',')
        }

        let nodeType = 'Depo'
        if (this.boxInfo.type === BOX_ITEM_KIND.LastDepo) {
          nodeType = 'LastDepo'
        } else if (this.boxInfo.type === BOX_ITEM_KIND.Drop) {
          nodeType = 'Drop'
        }
        return this.getTooltipText(nodeType, this.boxInfo, calculatedValues)
      } else if (this.boxInfo.type === BOX_ITEM_KIND.DepoStart) {
        let endTime =
          this.getMmddHhmmFromDateTimeString14(this.boxInfo.gantt.endTime) || ''

        let calculatedValues = {}
        calculatedValues['endTime'] = endTime

        return this.getTooltipText('DepoStart', this.boxInfo, calculatedValues)
      } else if (this.boxInfo.type === BOX_ITEM_KIND.DepoEnd) {
        let beginTime =
          this.getMmddHhmmFromDateTimeString14(this.boxInfo.gantt.beginTime) ||
          ''

        let calculatedValues = {}
        calculatedValues['beginTime'] = beginTime

        return this.getTooltipText('DepoEnd', this.boxInfo, calculatedValues)
      } else {
        return ''
      }
    },
    hasError() {
      if (this.boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        return this.boxInfo.trips.some((trip) => {
          if (this.hasErrorLevelInTrip(trip, BOX_EDIT_ERROR_LEVEL.Error)) {
            return true
          }
        })
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Trip) {
        return this.hasErrorLevelInTrip(
          this.boxInfo,
          BOX_EDIT_ERROR_LEVEL.Error
        )
      } else if (
        this.boxInfo.type === BOX_ITEM_KIND.LastDepo ||
        this.boxInfo.type === BOX_ITEM_KIND.Depo ||
        this.boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        return this.hasErrorLevelInItem(
          this.boxInfo,
          BOX_EDIT_ERROR_LEVEL.Error
        )
      }
      return false
    },
    hasWarning() {
      if (this.hasError) {
        // 上位のエラーレベル優先
        return false
      }
      if (this.boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        return this.boxInfo.trips.some((trip) => {
          if (this.hasErrorLevelInTrip(trip, BOX_EDIT_ERROR_LEVEL.Warning)) {
            return true
          }
        })
      } else if (this.boxInfo.type === BOX_ITEM_KIND.Trip) {
        return this.hasErrorLevelInTrip(
          this.boxInfo,
          BOX_EDIT_ERROR_LEVEL.Warning
        )
      } else if (
        this.boxInfo.type === BOX_ITEM_KIND.LastDepo ||
        this.boxInfo.type === BOX_ITEM_KIND.Depo ||
        this.boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        return this.hasErrorLevelInItem(
          this.boxInfo,
          BOX_EDIT_ERROR_LEVEL.Warning
        )
      }
      return false
    },
    errorIconStyle() {
      return 'color:red;'
    },
    warningIconStyle() {
      return 'color:black;'
    },
  },

  methods: {
    onClick() {
      this.$emit('click', this.boxInfo)
    },
    getTooltipText(nodeType, boxInfo, calculatedValues) {
      const tooltipItems = this.chartSettings.BoxInfo.Items.find(
        (e) => e.NodeType === nodeType
      ).TooltipSettings.TooltipItems

      let tooltip = []
      tooltipItems.forEach((item) => {
        let text =
          this.getValueForTooltip(boxInfo, calculatedValues, item.FieldName) ||
          ''
        if (item.Format && text) {
          text = item.Format.replace('%s', text)
        }
        if (item.ShowWhenExist) {
          if (text) {
            tooltip.push(item.Title)
            tooltip.push(text)
          }
        } else {
          tooltip.push(item.Title)
          tooltip.push(text)
        }
      })
      return tooltip.join('\n')
    },
    getValueForTooltip(boxInfo, calculatedValues, fieldName) {
      const keys = fieldName.split('.')
      let value
      if (keys.length > 1 && keys[0] === 'calculated') {
        value = calculatedValues[keys[1]]
      } else {
        value = boxInfo
        keys.forEach((key) => {
          if (value) {
            value = value[key]
          }
        })
      }

      return value
    },
    getMmddHhmmFromDateTimeString14(datetimeString) {
      let dateTime = ''
      if (datetimeString && datetimeString.length >= 8) {
        dateTime =
          datetimeString.substr(4, 2) + '/' + datetimeString.substr(6, 2)
      }
      if (datetimeString && datetimeString.length >= 12) {
        if (dateTime && dateTime.length > 0) {
          dateTime = dateTime + ' '
        }
        dateTime =
          dateTime +
          datetimeString.substr(8, 2) +
          ':' +
          datetimeString.substr(10, 2)
      }
      return dateTime
    },
    hasErrorLevelInTrip(trip, level) {
      return trip.items.some((item) => {
        if (this.hasErrorLevelInItem(item, level)) {
          return true
        }
      })
    },
    hasErrorLevelInItem(item, level) {
      return item.orderNodes.some((orderNode) => {
        if (orderNode.errorLevel === level) {
          return true
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tb {
  float: left;
  border: 1px black solid;
  width: 120px;
  font-size: 14px;
  color: #000000;
  position: relative;
  margin: 2px;
}
.selected {
  border: 2px red solid;
  margin-bottom: 1px;
}
.tb tr td {
  text-align: center;
  border: 1px #000000 solid;
  height: 25px;
}
</style>
